
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

type TextStyle = 'medium' | 'semi-bold' | 'bold'
type TextArray = string | { text: string, style: TextStyle }

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Array] as PropType<string | TextArray[]>,
      required: true,
    },
  },
  setup() {
    const password = ref('')
    const classMap: Record<TextStyle, string> = {
      medium: 'font-weight-medium',
      'semi-bold': 'font-weight-semi-bold',
      bold: 'font-weight-bold',
    }
    return { password, classMap }
  },
})
